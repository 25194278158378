import React, { forwardRef } from "react";

import { getOptImage } from "_common/utils";

import Presentation from "./presentation";

const component = forwardRef ( ( { useOriginal, type, src, ...props }, ref ) => {
	const getOptimisedImage = src => {

		if ( typeof src != "string" )
			return;

		if ( useOriginal )
			return src;

		return getOptImage ( src, type );
	};

	return (
		<Presentation
			{ ...props }
			ref = { ref }
			og  = { src }
			src = { getOptimisedImage ( src ) }
		/>
	);
} );

component.displayName = "component";

export default component;
