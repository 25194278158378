import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	const btnState = props?.active ? "clicked" : "default";
	const button   = theme.other.colors.textBtn;

	return {
		primaryButton: {
			background : button[btnState].background,
			":hover"   : {
				background: button.hover.background
			}
		},
		buttonText: {
			color: button[btnState].text
		}
	};
} );
