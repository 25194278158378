import { Text } from "@mantine/core";
import Linkify  from "linkify-react";

import useStyles from "./styles";

const component = props => {
	const {
		title,
		className,
		variant = "label",
		color,
		component = "div",
		onClick,
		style,
		noWrap,
		linkify,
		themeOverride,
		dangerouslySetInnerHTML,
		dimmed,
		unstyled,
		bold,
		weight,
		isWebview,
		...others
	} = props;

	const { classes, cx } = useStyles ( { dimmed, noWrap, variant, color, themeOverride, isWebview } );

	if ( unstyled !== undefined )
		return (
			<>
				{title}
			</>
		);

	const Title = () => {
		if ( !linkify )
			return (
				<>
					{title}
				</>
			);

		return (
			<Linkify options = { { target: "_blank", className: "linkify" } }>
				{title}
			</Linkify>
		);
	};

	if ( dangerouslySetInnerHTML ) {
		return (
			<Text
				className               = { cx ( { [classes.noWrap]: noWrap === true }, classes.typography, className ) }
				component               = { component }
				dangerouslySetInnerHTML = { dangerouslySetInnerHTML }
				fw                      = { bold !== undefined ? "500" : weight }
				onClick                 = { onClick }
				style                   = { style }
				variant                 = { variant }
				{ ...others }
			/>
		);
	}

	return (
		<Text
			className = { cx ( { [classes.noWrap]: noWrap }, classes.typography, className ) }
			component = { component }
			fw        = { bold !== undefined ? "500" : weight }
			onClick   = { onClick }
			style     = { style }
			variant   = { variant }
			{ ...others }
		>
			<Title />
		</Text>
	);
};

export default component;
