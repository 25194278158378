import { Button } from "@mantine/core";

const BaseButton = ( {
	title,
	dataTestid,
	children,
	...rest
} ) => {

	if ( children ) {
		return (
			<Button
				{ ...rest }
				data-testid = { dataTestid }
			>
				{children}
			</Button>
		);
	}

	return (
		<Button
			{ ...rest }
			data-testid = { dataTestid }
			styles      = { {
				root: {
					borderRadius : "1rem",
					border       : "none",
					padding      : "1.2rem 3rem",
					height       : "unset"
				},
				label: {
					fontWeight : "500",
					overflow   : "visible"
				}
			} }
		>
			{ title }
		</Button>
	);
};

export default BaseButton;
