import { toast } from "react-toastify";

import Icon from "_components/atoms/icon";

const info = data => {
	if ( !data )
		return;

	return toast.info ( data, {
		closeButton: ( { closeToast } ) => (
			<Icon
				modeType = { "dark" }
				name     = { "cross" }
				onClick  = { closeToast }
				style    = { {
					width  : "2.4rem",
					cursor : "pointer"
				} }
			/>
		)
	} );
};

const error = data => {
	if ( !data )
		return;

	return toast.error ( data, {
		closeButton: ( { closeToast } ) => (
			<Icon
				modeType = { "dark" }
				name     = { "cross" }
				onClick  = { closeToast }
				style    = { {
					width  : "2.4rem",
					cursor : "pointer"
				} }
			/>
		)
	} );
};

const warn = data => {
	if ( !data )
		return;

	return toast.warning ( data, {
		closeButton: ( { closeToast } ) => (
			<Icon
				modeType = { "dark" }
				name     = { "cross" }
				onClick  = { closeToast }
				style    = { {
					width  : "2.4rem",
					cursor : "pointer"
				} }
			/>
		)
	} );
};

const success = data => {
	if ( !data )
		return;

	return toast.success ( data, {
		closeButton: ( { closeToast } ) => (
			<Icon
				modeType = { "dark" }
				name     = { "cross" }
				onClick  = { closeToast }
				style    = { {
					width  : "2.4rem",
					cursor : "pointer"
				} }
			/>
		)
	} );
};

const loading = data => {
	if ( !data )
		return;

	return toast.info ( data, {
		draggable   : false,
		autoClose   : false,
		closeButton : null
	} );
};

export default {
	info,
	error,
	warn,
	success,
	loading
};
