const component = ( { preload, src } ) => {
	if ( !preload )
		return null;

	return (
		<link
			as   = "image"
			href = { src }
			rel  = "preload"
		/>
	);
};

export default component;
