import Component       from "./container";
import MainButton      from "./mainButton";
import PlainButton     from "./plainButton";
import PrimaryButton   from "./primaryButton";
import SecondaryButton from "./secondaryButton";
import TertiaryButton  from "./tertiaryButton";
import TextButton      from "./textButton";

export default Component;

export { MainButton, PrimaryButton, TertiaryButton, SecondaryButton, PlainButton, TextButton };