import useStyles   from "_styles/atoms/buttons/primaryButton";
import { palette } from "_styles/theme";

import BaseButton from "./baseButton";

const PrimaryButton = ( {
	key,
	className,
	onClick = () => { },
	leftSection,
	rightSection,
	active,
	size = "md",
	title,
	fullWidth,
	buttonTextClassName,
	dataTestid,
	loading,
	disabled,
	disabledTextColor,
	id,
	type = "submit",
	...rest
} ) => {

	const { classes, cx } = useStyles ( { active, disabledTextColor }, { name: "primaryButton" } );

	return (
		<BaseButton
			key          = { key }
			classNames   = { {
				root  : cx ( classes.primaryButton, className ),
				label : cx ( classes.buttonText, buttonTextClassName )
			} }
			dataTestid   = { dataTestid }
			disabled     = { disabled }
			fullWidth    = { fullWidth }
			id           = { id }
			leftSection  = { leftSection }
			loaderProps  = { { color: palette.secondaryWhite } }
			loading      = { loading }
			onClick      = { onClick }
			rightSection = { rightSection }
			size         = { size }
			title        = { title }
			type         = { type }
			{ ...rest }
		/>
	);
};

export default PrimaryButton;
