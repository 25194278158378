import { forwardRef } from "react";

import useStyles from "_styles/atoms/backgroundWrapper";

const BackgroundWrapper = forwardRef ( ( { children, className, type = "primary", ...otherProps }, ref ) => {
	const { classes } = useStyles ( { type } );

	return (
		<div ref   = { ref }
			className = { `${ classes.background } ${ className }` }
			{ ...otherProps }
		>
			{children}
		</div>
	);
} );

BackgroundWrapper.displayName = "containerBackgroundWrapper";

export default BackgroundWrapper;