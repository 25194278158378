
import { Button } from "@mantine/core";
import React      from "react";

const PlainButton = ( { title, ...props } ) => {

	return (
		<Button
			{ ...props }
		>
			{title}
		</Button>
	);
};

export default PlainButton;