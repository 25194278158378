import Image from "_components/atoms/image";

import useStyles from "./styles";

const Component = ( {
	name,
	modeType = "dark",
	className,
	size,
	onClick,
	style,
	themeBased,
	...props
} ) => {

	const { classes, cx } = useStyles ( { size } );

	let src = !themeBased ? `/svg/common/${ name }.svg` : `/svg/${ modeType }Mode/${ name }.svg`;

	return (
		<Image
			useOriginal
			className = { cx ( classes.container, className ) }
			onClick   = { onClick }
			src       = { src }
			style     = { style }
			{ ...props }
		/>
	);

};

export default Component;
