import XHR         from "_common/xhr";
import { configs } from "_config/index";

export const getChannelAnalytics = async function ( type = "all", startTime = 0, endTime = 0 ) {
	let url = "";

	if ( type === "all" ) {
		url = `${ configs.STUDIO_URL }creator_dashboard/getChannelAnalytics`;
	} else {
		url = `${ configs.STUDIO_URL }creator_dashboard/getChannelAnalytics?start_time=${ startTime }&end_time=${ endTime }`;
	}

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getCreationBasedAnalytics = async function ( type = "all", creationType = "Broadcast", startTime = 0, endTime = 0 ) {
	let url = "";

	if ( type === "all" ) {
		url = `${ configs.STUDIO_URL }creator_dashboard/getCreationBasedAnalytics?creationType=${ creationType }`;
	} else {
		url = `${ configs.STUDIO_URL }creator_dashboard/getCreationBasedAnalytics?creationType=${ creationType }&start_time=${ startTime }&end_time=${ endTime }`;
	}

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getFollowersGraph = async function ( type = "all", startTime = 0, endTime = 0 ) {
	let url = "";

	if ( type === "all" ) {
		url = `${ configs.STUDIO_URL }creator_dashboard/getFollowersGraph`;
	} else {
		url = `${ configs.STUDIO_URL }creator_dashboard/getFollowersGraph?start_time=${ startTime }&end_time=${ endTime }`;
	}

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getViewsGraph = async function ( type = "all", filterParam = "ALL", startTime = 0, endTime = 0 ) {
	let url = "";

	if ( type === "all" ) {
		url = `${ configs.STUDIO_URL }creator_dashboard/getViewsGraph?filter_param=${ filterParam }`;
	} else {
		url = `${ configs.STUDIO_URL }creator_dashboard/getViewsGraph?filter_param=${ filterParam }&start_time=${ startTime }&end_time=${ endTime }`;
	}

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getRevenueGraph = async function ( type = "all", filterParam = "ALL", startTime = 0, endTime = 0 ) {
	let url = "";

	if ( type === "all" ) {
		url = `${ configs.STUDIO_URL }creator_dashboard/getRevenueGraph?filter_param=${ filterParam }`;
	} else {
		url = `${ configs.STUDIO_URL }creator_dashboard/getRevenueGraph?filter_param=${ filterParam }&start_time=${ startTime }&end_time=${ endTime }`;
	}

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getLatestContent = async function ( { pageNumber = 1, pageSize = 10, filterParam = "ALL", startTime = "", endTime = "", sortingParam = "date", sortingOrder = "DESC" } ) {

	// filterParam   => ALL, LIVE, REEL, VIDEO (default value = "ALL") -----> any case will work
	// startTime     => starting time for filter (default value = "")
	// endTime       => ending time for filter (default value = "")
	// pageNumber    => 1 default (for pagination)
	// pageSize      => 10 default (for pagination)
	// sortingParam  => DATE, LIKES, COMMENTS, SHARES, VIEWS, REVENUE, MONETIZEDVIEWS  (default value DATE)-----> any case will work
	// sortingOrder  => ASC, DESC (default value DESC) -----> any case will work

	// DESC ----> filtering content on top according to greatest value of sortingParam.
	// ASC  ----> filtering content on top according to lowest value of sortingParam.

	const response = await XHR.get.bind ( this ) ( {
		url     : `${ configs.STUDIO_URL }creator_dashboard/getLatestContent?page_number=${ pageNumber }&page_size=${ pageSize }&filter_param=${ filterParam }&start_time=${ encodeURIComponent ( startTime ) }&end_time=${ encodeURIComponent ( endTime ) }&sorting_param=${ sortingParam }&sorting_order=${ sortingOrder }`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getLiveStreamMonitizationJourney = async function ( ) {

	const response = await XHR.get.bind ( this ) ( {
		url     : `${ configs.STUDIO_URL }creator_dashboard/getStreamMonetisationData`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getPostMonitizationJourney = async () => {

	const response = await XHR.get ( {
		url     : `${ configs.STUDIO_URL }creator_dashboard/getPostMonetisationData`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const setMonetisationStatus = async function ( type ) {
	const apiType = type === "live" ? "getStreamMonetisationData" : "getPostMonetisationData";

	const response = await XHR.get.bind ( this ) ( {
		url     : `${ configs.STUDIO_URL }creator_dashboard/${ apiType }?modal_state=1`,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getRevenueData = async function ( startTime, endTime ) {

	let url = `${ configs.STUDIO_URL }creator_dashboard/getRevenueData?start_time=${ startTime }&end_time=${ endTime }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getDiamondCountByMonth = async function ( startTime, endTime ) {

	let url = `${ configs.STUDIO_URL }creator_dashboard/getDiamondCountByMonth?start_time=${ startTime }&end_time=${ endTime }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getMonetisationTransactionHistory = async function ( params ) {
	const {
		pageNo,
		pageSize
	} = params;

	let url = `${ configs.STUDIO_URL }creator_dashboard/getMonetisationTransactionHistory?pageNo=${ pageNo }&pageSize=${ pageSize }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getMonetisationPayoutRequest = async function ( payoutId ) {

	let url = `${ configs.STUDIO_URL }creator_dashboard/getPayoutDetails?payoutId=${ payoutId }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const sendPaytmMonetisationPayoutRequest = async body => {

	const response = await XHR.post ( {
		url     : `${ configs.STUDIO_URL }creator_dashboard/redeemMonetisation`,
		headers : {
			"Content-Type": "application/json"
		},
		data: body
	} );

	return response;
};

export const getMigrateData = async function ( userId ) {

	if ( !userId )
		return;

	let url = `${ configs.STUDIO_URL }creator_dashboard/migrateData?userId=${ userId }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getCreatorDashboardStatus = async function ( isViewed ) {

	let url = `${ configs.STUDIO_URL }creator_dashboard/getCreatorDashboardStatus`;

	if ( isViewed )
		url = `${ configs.STUDIO_URL }creator_dashboard/getCreatorDashboardStatus?isViewed=${ isViewed }`;

	const response = await XHR.get.bind ( this ) ( {
		url     : url,
		headers : {
			"Content-Type": "application/json"
		}
	} );

	return response;
};

export const getVideoAndReelMonetisationStatus = async () => {
	const response = await XHR.get ( {
		url: `${ configs.STUDIO_URL }creator_dashboard/getMonetisationStatus`
	} );

	if ( !response )
		return null;

	return response;
};

export const updateReelVideoModalState = async () => {
	await XHR.post ( {
		url: `${ configs.STUDIO_URL }creator_dashboard/updateReelVideoModalState`
	} );

	return;
};

