import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {

	return {
		typography: {
			color: props?.color === "secondary" || props?.dimmed ?
				theme.other.colors.text.secondary :
				props?.color === "primary" ?
					( props?.isWebview ? theme.other.colors.text.webview : theme.other.colors.text.primary ) :
					props?.color || ( props?.isWebview ? theme.other.colors.text.webview : theme.other.colors.text.primary ),
			margin: "revert"
		},
		noWrap: {
			overflow     : "hidden",
			textOverflow : "ellipsis",
			whiteSpace   : "nowrap"
		}
	};
} );
