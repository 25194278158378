import React from "react";

import useStyles from "_styles/atoms/buttons/tertiaryButton";

import BaseButton from "./baseButton";

const TertiaryButton = ( {
	key,
	className,
	onClick = () => {},
	leftSection,
	rightSection,
	size,
	loading,
	active,
	title,
	fullWidth,
	fontWeight = "normal",
	buttonTextClassName,
	...rest
} ) => {
	const { classes, cx } = useStyles ( { size, active, fullWidth, fontWeight } );

	return (
		<BaseButton
			key          = { key }
			classNames   = { {
				root  : cx ( className, classes.tertiaryButton ),
				label : cx ( buttonTextClassName, classes.buttonText )
			} }
			fullWidth    = { fullWidth }
			leftSection  = { leftSection }
			loading      = { loading }
			onClick      = { onClick }
			rightSection = { rightSection }
			size         = { size }
			title        = { title }
			variant      = { "outline" }
			{ ...rest }
		/>
	);
};

export default TertiaryButton;
