import React from "react";

import { useGetIsWebview } from "_common/hooks/global";

import Presentation from "./presentation";

const component = ( {
	title,
	className,
	variant,
	color,
	component,
	onClick,
	style,
	noWrap,
	linkify,
	dangerouslySetInnerHTML,
	themeOverride,
	dimmed,
	unstyled,
	bold,
	...others
} ) => {
	const isWebview = useGetIsWebview ();

	return (
		<Presentation
			bold                    = { bold }
			className               = { className }
			color                   = { color }
			component               = { component }
			dangerouslySetInnerHTML = { dangerouslySetInnerHTML }
			dimmed                  = { dimmed }
			isWebview               = { isWebview }
			linkify                 = { linkify }
			noWrap                  = { noWrap }
			onClick                 = { onClick }
			style                   = { style }
			themeOverride           = { themeOverride }
			title                   = { title }
			unstyled                = { unstyled }
			variant                 = { variant }
			{ ...others }
		/>
	);
};

export default component;
