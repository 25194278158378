import { Button, useMantineTheme } from "@mantine/core";
import React                       from "react";

const MainButton = props => {
	const theme = useMantineTheme ();
	const {
		key,
		className,
		size = "md",
		variant = "outline",
		color = "primary",
		onClick = () => {},
		title,
		rightIcon,
		dataTestid,
		fullWidth = false,
		disabled,
		leftIcon,
		id,
		classNames
	} = props;

	return (
		<Button
			key         = { key }
			className   = { className }
			classNames  = { classNames }
			color       = { color }
			data-testid = { dataTestid }
			disabled    = { disabled }
			fullWidth   = { fullWidth }
			id          = { id }
			leftIcon    = { leftIcon }
			onClick     = { onClick }
			rightIcon   = { rightIcon }
			size        = { size }
			variant     = "default"
			// variant     = { variant }
		>
			{ title }
		</Button>
	);
};

export default MainButton;
